
@import "../../../styles/abstracts/_variables.scss";

.dashboard__table {

    .row.data-row div:last-child {
        text-align: left;

        a {
            text-decoration: underline;
        }
    }

    .not-read {
        background-color: $rodeo-dust;

        a {
            font-weight: 500;
        }
    }
}
