<template>
  <NdsHeading title="Avbetalningskrediter" type="h4" tight>
    <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
      @click="hirePurchaseQuestionModal.openModal" aria-label="Förklaring Avbetalningskrediter" />
  </NdsHeading>
  <div v-if="hasTerminatedHirePurchase" class="d-flex justify-content-end my-3">
    <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
      variant="secondary" @click="showTerminated = !showTerminated">
      {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
    </NdsButton>
  </div>
  <NdsAccordion v-if="paginatedHirePurchase.length > 0" v-model="activeAccordionId" :accordions="paginatedHirePurchase">
    <template v-for="(inquiry, index) in paginatedHirePurchase" #[inquiry.id] :key="index">
      <div class="p-3">
        <div class="row pb-3">
          <div class="col-8">Kreditlimit:</div>
          <div class="col-4 pl-0 text-right">{{ inquiry.grantedCredit }}</div>
          <div class="col-8 color-ocean">Utnyttjad kredit:</div>
          <div class="col-4 pl-0 color-ocean text-right">{{ inquiry.usedCredit }}</div>
        </div>
        <div class="row">
          <template v-if="inquiry.fromDate">
            <div class="col-8">Startdatum:</div>
            <div class="col-4 pl-0 text-right">{{ inquiry.fromDate }}</div>
          </template>
          <template v-if="!inquiry.toDate || inquiry.usedCredit > 0">
            <div class="col-8">Senast uppdaterad:</div>
            <div class="col-4 pl-0 text-right">{{ inquiry.updatedAtDate }}</div>
          </template>
          <template v-if="inquiry.toDate">
            <div class="col-8 color-red">Avslutad:</div>
            <div class="col-4 pl-0 color-red text-right">{{ inquiry.toDate }}</div>
          </template>
        </div>
      </div>
    </template>
  </NdsAccordion>
  <ZeroState v-else icon="fas fa-credit-card"
    :text="model.descriptionNoHirePurchaseDevelopment.value || 'Inga avbetalningskrediter registrerade'" isRichText />
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
  <div class="mt-3">
    <NdsHeading title="Kreditutveckling" type="h4" tight>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
        @click="creditDevelopmentQuestionModal.openModal" aria-label="Förklaring Kreditutveckling" />
    </NdsHeading>
    <div class="ndsCreditChartContainer">
      <NdsCreditPlotChart title="" emptyTitle="Ingen kreditutveckling är registrerad" :chartData="chartData" />
    </div>
  </div>
  <NdsFooter class="mt-3" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
  <NdsConfirmModal v-bind="hirePurchaseQuestionModal.props" v-model="hirePurchaseQuestionModal.isOpen.value"
    @onConfirm="hirePurchaseQuestionModal.closeModal" @onCancel="hirePurchaseQuestionModal.closeModal"
    @onDismiss="hirePurchaseQuestionModal.dismissModal" />
  <NdsConfirmModal v-bind="creditDevelopmentQuestionModal.props" v-model="creditDevelopmentQuestionModal.isOpen.value"
    @onConfirm="creditDevelopmentQuestionModal.closeModal" @onCancel="creditDevelopmentQuestionModal.closeModal"
    @onDismiss="creditDevelopmentQuestionModal.dismissModal" />
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { formatToSek, FormatDate } from '../_helpers.js';
import { NdsHeading, NdsIconButton, NdsButton, NdsConfirmModal, useConfirmModal, NdsCreditPlotChart, NdsAccordion, NdsPagination, NdsFooter, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import { useEbbot } from '../utils/ebbotUtils.js';

const props = defineProps({
  model: Object,
  report: Object,
});

const showTerminated = ref(true);
const hasTerminatedHirePurchase = ref(false);

const formattedHirePurchase = computed(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    return props.report.creditReport.ctiCreditsWithInquirer.creditInformations
      .filter((creditInfo) => {
        const isHirePurchase = props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInfo.creditNumber] === 4;
        if (isHirePurchase && creditInfo.toDate && !hasTerminatedHirePurchase.value) {
          hasTerminatedHirePurchase.value = true;
        }
        return isHirePurchase;
      })
      .map((hirePurchase, index) => ({
        id: `downPaymentCredit ${index + 1}`,
        title: hirePurchase.creditor ?? "Okänd långivare",
        grantedCredit: hirePurchase.grantedCredit != null ? formatToSek(hirePurchase.grantedCredit) : null,
        usedCredit: hirePurchase.usedCredit != null ? formatToSek(hirePurchase.usedCredit) : null,
        fromDate: hirePurchase.fromDate?.timeStamp.value ? FormatDate(hirePurchase.fromDate.timeStamp.value) : null,
        toDate: hirePurchase.toDate?.timeStamp.value ? FormatDate(hirePurchase.toDate.timeStamp.value) : null,
        updatedAtDate: hirePurchase.updatedAtDate?.timeStamp.value ? FormatDate(hirePurchase.updatedAtDate.timeStamp.value) : null,
      }));
  }
  return [];
});

const filteredFormattedHirePurchase = computed(() => (formattedHirePurchase.value.filter(hirePurchase => (showTerminated.value ? true : !hirePurchase.toDate))));

const { showEbbot, hideEbbot } = useEbbot();

const hirePurchaseQuestionModal = useConfirmModal({
  title: props.model.questionHirePurchaseTitle.value || 'Avbetalningskrediter',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionHirePurchase.value,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const creditDevelopmentQuestionModal = useConfirmModal({
  title: props.model.questionHirePurchaseDevelopmentTitle.value || 'Kreditutveckling',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionHirePurchaseDevelopment.value,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const chartData = computed(() => ({
  snapshotLabel: 'Nuläge',
  hasSnapshot: true,
  label: 'Utnyttjad kredit',
  entries: props.report.hirePurchaseData.entries,
}));

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedHirePurchase,
} = usePagination(filteredFormattedHirePurchase);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
<style scoped>
.ndsCreditChartContainer {
  margin-top: 8px;
  width: 100%;
  height: 320px;
}
</style>