
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";
@import "../../../styles/abstracts/_mixins.scss";

.daysLeftLabel {
  background-color: $gray;
  border-radius: 6px;
  padding: 0 5px;
  top: 20px;
  right: -15px;
}

.product__circle {
  min-width: 146px;
  min-height: 146px;
  text-align: center;
  border-radius: 50%;
  background-color: $beach;
  border: 2px solid $rodeo-dust;

  .items {
    display: flex;
    flex-direction: column;
  }

  .more-info {
    bottom: 8px;
  }

  &.disabled {
    background-color: $gallery;

    i {
      color: $silver;
    }
  }

  span {
    display: block;
    width: 100%;
    text-align: center;

    i {
      color: $orangegold;
    }
  }
}

.uc-score-not-loaded {
  min-height: 228px;
  position: relative;

  .uc-score-link {
    position: absolute;
    bottom: 0;
  }
}

.eventlist {
  ul {
    list-style-type: none;
    padding-left: 0;

    a {
      background-color: transparent;
      color: $black;
      padding: 0.75rem;

      @include attention() {
        background-color: $light-smoke;
        text-decoration: none;
      }

      &.not-read {
        background-color: $rodeo-dust;

        @include attention() {
          background-color: $rodeo-dust-hover;
        }
      }

      li {
        span {
          padding: 4px 6px;
        }

        i {
          color: $orangegold;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
            color: $dark-ocean;
          }
        }
      }
    }
  }
}
